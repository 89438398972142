import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Header, Image, Table } from "semantic-ui-react";

import CustomerClient from "../../services/CustomerClient";
import { LoadingState } from "../../services/http";

const customerClient = new CustomerClient();

const ExpiredOffersView = ({ customerId }) => {
  const [offers, setOffers] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);

  useEffect(() => {
    getExpiredOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const getExpiredOffers = () => {
    customerClient
      .getExpiredOrUsedOffers(customerId)
      .then(({ data }) => {
        setOffers(data.offers);
        setLoadingState(LoadingState.SUCCESS);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LoadingState.FAILED);
      });
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  let content;

  switch (loadingState) {
    case LoadingState.SUCCESS:
      content =
        offers.length > 0 ? (
          <Table style={{ width: "100%" }} basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Offer</Table.HeaderCell>
                <Table.HeaderCell>Points</Table.HeaderCell>
                <Table.HeaderCell>Valid From</Table.HeaderCell>
                <Table.HeaderCell>Expired</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {offers.map((offer, key) => (
                <Table.Row key={key}>
                  <Table.Cell>
                    <Header as="h4">
                      <Image src={offer.imgUrl} shape="rounded" size="mini" />
                      <Header.Content>
                        {offer.title}
                        <Header.Subheader>{offer.description}</Header.Subheader>
                      </Header.Content>
                    </Header>
                  </Table.Cell>
                  <Table.Cell>{offer.points}</Table.Cell>
                  <Table.Cell>{formattedDate(offer.validFrom)}</Table.Cell>
                  <Table.Cell>{formattedDate(offer.expires)}</Table.Cell>
                  <Table.Cell>{offer.state}</Table.Cell>
                  <Table.Cell>{offer.status}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <span>No expired offers.</span>
        );
      break;
    case LoadingState.PENDING:
      content = "Loading expired offers...";
      break;
    default:
      content = "Failed to load expired offers.";
      break;
  }

  return <div>{content}</div>;
};

ExpiredOffersView.propTypes = {
  customerId: PropTypes.string.isRequired,
};

export default ExpiredOffersView;
