import React, { useEffect, useState } from "react";
import { Dropdown, Icon, Input, Transition } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, TextInput } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Basket, Cancel, InfoCircle } from "@jsluna/icons";
import { Modal, ModalHeading } from "@jsluna/modal";

import ProductClient from "../../services/ProductClient";
import GroupDetailAspect from "./GroupDetailAspect";
import GroupListAspect from "./GroupListAspect";
import ProductSearchAspect from "./ProductSearchAspect";

const productClient = new ProductClient();
const TRANSITION_DURATION = 750;

const ProductsView = () => {
  const [productMode, setProductMode] = useState(true);
  const [groupMode, setGroupMode] = useState(false);
  const [groupDetailMode, setGroupDetailMode] = useState(false);
  const [viewingGroup, setViewingGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilters, setSearchFilters] = useState({});
  const [categoryDropdownOptions, setCategoryDropdownOptions] = useState({
    super_category: [],
    category: [],
    sub_category: [],
    segment: [],
  });
  const [createGroupInput, setCreateGroupInput] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [confirmLock, setConfirmLock] = useState(false);

  const handleSearchTermChange = (e) => setSearchTerm(e.target.value);

  const clearSearchFilters = (hierarchyLevel) => {
    const keys = getSubHierarchy(hierarchyLevel);
    return keys.reduce((obj, item) => ({ ...obj, [item]: "" }), {});
  };

  const clearCategoryOptions = (hierarchyLevel) => {
    const keys = getSubHierarchy(hierarchyLevel);
    return keys.reduce((obj, item) => ({ ...obj, [item]: [] }), {});
  };

  const handleCategoryChange = (e, { name, value }) => {
    const newFilters = {
      ...searchFilters,
      [name]: value,
      ...clearSearchFilters(name),
    };
    if (value !== searchFilters[name]) {
      setSearchFilters(newFilters);
      setCategoryDropdownOptions({
        ...categoryDropdownOptions,
        ...clearCategoryOptions(name),
      });
      if (value) {
        const thisLevelIdx = hierarchy().findIndex((x) => x === name);
        const nextLevelName = hierarchy()[thisLevelIdx + 1];
        if (thisLevelIdx + 1 < hierarchy().length) {
          loadCategoriesForHierarchyLevel(newFilters, nextLevelName);
        }
      }
    }
  };

  const loadCategoriesForHierarchyLevel = (filters, targetLevel) => {
    productClient.searchProductHierarchy(filters).then((categories) => {
      const mappedCategories = categories.map((x) => ({
        key: x.key,
        value: x.key,
        text: x.name,
      }));
      mappedCategories.unshift({ key: "", value: "", text: "none" });
      const updatedCategoryDropdownOptions = {
        ...categoryDropdownOptions,
        [targetLevel]: mappedCategories,
      };
      setCategoryDropdownOptions(updatedCategoryDropdownOptions);
      setSearchFilters(filters);
    });
  };

  const hierarchy = () => [
    "super_category",
    "category",
    "sub_category",
    "segment",
  ];

  const getSubHierarchy = (hierarchyNode) => {
    const idx = hierarchy().findIndex((x) => x === hierarchyNode);
    return hierarchy().slice(idx + 1);
  };

  const handleProductSelection = (product) => {
    const updatedSelectedProducts = [...selectedProducts];
    const index = updatedSelectedProducts.indexOf(product);

    if (index !== -1) {
      updatedSelectedProducts.splice(index, 1);
    } else {
      updatedSelectedProducts.push(product);
    }
    setSelectedProducts(updatedSelectedProducts);
  };

  const handleGroupCheck = (group) => {
    const updatedSelectedGroups = [...selectedGroups];
    const index = updatedSelectedGroups.indexOf(group);

    if (index !== -1) {
      updatedSelectedGroups.splice(index, 1);
    } else {
      updatedSelectedGroups.push(group);
    }
    setSelectedGroups(updatedSelectedGroups);
  };

  const handleGroupSelect = (group) => {
    setViewingGroup(group);
    toggleMode("groupDetailMode");
  };

  const handleAddProductToGroup = () => {
    if (selectedGroups.length && selectedProducts.length) {
      selectedProducts.forEach((prod) =>
        selectedGroups.forEach((grp) =>
          productClient.addProductToGroup(grp.id, prod.ean, prod.sku)
        )
      );
      setConfirmOpen(true);
      setConfirmMessage(
        `Attempted to add ${selectedProducts.length} products to ${selectedGroups.length} groups.`
      );
      setSelectedProducts([]);
      setSelectedGroups([]);
    }
  };

  useEffect(() => {
    loadCategoriesForHierarchyLevel({}, "super_category");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMode = (mode) => {
    setProductMode(false);
    setGroupMode(false);
    setGroupDetailMode(false);
    setTimeout(() => {
      if (mode === "productMode") setProductMode(true);
      if (mode === "groupMode") setGroupMode(true);
      if (mode === "groupDetailMode") setGroupDetailMode(true);
    }, TRANSITION_DURATION);
  };

  const handleConfirm = () => setConfirmOpen(false);

  const buildGroupDetails = () => (
    <div>
      <GroupDetailAspect group={viewingGroup}>
        {viewingGroup.status !== "LOCKED" && (
          <OutlinedButton onClick={() => setConfirmLock(true)}>
            Lock Group
          </OutlinedButton>
        )}
        <Modal
          open={confirmLock}
          fullScreen
          restrictClose
          alert
          headingId="dialog-modal"
          className="modal-overflow"
        >
          <ModalHeading element="h3">
            Are you sure? This cannot be undone.
          </ModalHeading>
          <ButtonGroupPrimary>
            <FilledButton onClick={lockGroup}>Ok</FilledButton>
            <OutlinedButton onClick={() => setConfirmLock(false)}>
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </Modal>
        <span
          style={{ marginLeft: "80px" }}
          onClick={() => toggleMode("groupMode")}
        >
          Go to Group view <Icon name="file outline" size="large" />
        </span>
      </GroupDetailAspect>
    </div>
  );

  const lockGroup = () => {
    productClient
      .lockGroup(viewingGroup.id)
      .then(() => productClient.getGroup(viewingGroup.id))
      .then((grp) => {
        setConfirmLock(false);
        setGroupDetailMode(false);
        setViewingGroup(grp);
        setTimeout(() => setGroupDetailMode(true), 1000);
      });
  };

  const buildGroupList = () => (
    <div>
      <GroupListAspect
        onGroupCheck={handleGroupCheck}
        onGroupSelect={handleGroupSelect}
        selectedGroups={selectedGroups}
      >
        {selectedProducts.length > 0 && selectedGroups.length > 0 && (
          <OutlinedButton
            style={{ margin: "3px" }}
            onClick={handleAddProductToGroup}
          >
            {`Assign ${selectedProducts.length} selected products to ${selectedGroups.length} selected groups`}
          </OutlinedButton>
        )}
        <span
          style={{ marginLeft: "80px" }}
          onClick={() => toggleMode("productMode")}
        >
          Go to Product view <Icon name="file outline" size="large" />
        </span>
      </GroupListAspect>
    </div>
  );

  const buildProductSearch = () => (
    <div>
      <ProductSearchAspect
        searchTerm={searchTerm}
        searchFilters={searchFilters}
        selectedProducts={selectedProducts}
        onProductSelect={handleProductSelection}
      >
        <span onClick={() => toggleMode("groupMode")}>
          Go to Group view <Icon name="folder outline" size="large" />
        </span>
      </ProductSearchAspect>
    </div>
  );

  const buildCategorySelectors = () => (
    <div>
      <GridWrapper style={{ padding: "1.5rem 0" }}>
        <GridItem size={{ xs: "1/5" }}>
          <Input
            style={{
              width: "100%",
              backgroundColor: "transparent",
              boxShadow: "0",
              fontFamily: "sans-serif",
              border: "1px solid #737373",
              borderRadius: "2px",
              color: "#737373",
              minHeight: "48px",
            }}
            icon="search"
            placeholder="Search..."
            onChange={handleSearchTermChange}
          />
        </GridItem>
        <GridItem size={{ xs: "1/5" }}>
          <Dropdown
            style={{
              width: "100%",
              backgroundColor: "transparent",
              padding: "16px 8px 16px",
              boxShadow: "0",
              fontFamily: "sans-serif",
              border: "1px solid #737373",
              borderRadius: "2px",
              color: "#737373",
              minHeight: "48px",
            }}
            name="super_category"
            placeholder="Super category"
            selection
            search
            options={categoryDropdownOptions.super_category}
            onChange={handleCategoryChange}
            value={searchFilters.super_category}
          />
        </GridItem>
        <GridItem size={{ xs: "1/5" }}>
          <Dropdown
            style={{
              width: "100%",
              backgroundColor: "transparent",
              padding: "16px 8px 16px",
              boxShadow: "0",
              fontFamily: "sans-serif",
              border: "1px solid #737373",
              borderRadius: "2px",
              color: "#737373",
              minHeight: "48px",
            }}
            name="category"
            placeholder="Category"
            selection
            search
            options={categoryDropdownOptions.category}
            onChange={handleCategoryChange}
            value={searchFilters.category}
          />
        </GridItem>
        <GridItem size={{ xs: "1/5" }}>
          <Dropdown
            style={{
              width: "100%",
              backgroundColor: "transparent",
              padding: "16px 8px 16px",
              boxShadow: "0",
              fontFamily: "sans-serif",
              border: "1px solid #737373",
              borderRadius: "2px",
              color: "#737373",
              minHeight: "48px",
            }}
            name="sub_category"
            placeholder="Sub category"
            selection
            search
            options={categoryDropdownOptions.sub_category}
            onChange={handleCategoryChange}
            value={searchFilters.sub_category}
          />
        </GridItem>
        <GridItem size={{ xs: "1/5" }}>
          <Dropdown
            style={{
              width: "100%",
              backgroundColor: "transparent",
              padding: "16px 8px 16px",
              boxShadow: "0",
              fontFamily: "sans-serif",
              border: "1px solid #737373",
              borderRadius: "2px",
              color: "#737373",
              minHeight: "48px",
            }}
            name="segment"
            placeholder="Segment"
            selection
            search
            options={categoryDropdownOptions.segment}
            onChange={handleCategoryChange}
            value={searchFilters.segment}
          />
        </GridItem>
      </GridWrapper>
    </div>
  );

  const buildGroupNameStatusTag = () => (
    <div style={{ float: "right" }}>
      <Label>
        <InfoCircle className="ln-u-margin-left" /> {viewingGroup.name} [
        {viewingGroup.status}]
      </Label>
    </div>
  );

  const buildSelectedProductsWidget = () => (
    <div style={{ float: "right" }} key="selectedProducts">
      <OutlinedButton onClick={() => setSelectedProducts([])}>
        <Cancel /> de-select
      </OutlinedButton>
      <InfoCircle className="ln-u-margin-left" />
      <Label className="ln-u-margin-left">
        {selectedProducts.length} products selected.
      </Label>
    </div>
  );

  const buildSelectedGroupsWidget = () => (
    <div style={{ float: "right" }} key={"selectedGroups"}>
      <OutlinedButton onClick={() => setSelectedGroups([])}>
        <Cancel /> de-select
      </OutlinedButton>
      <InfoCircle className="ln-u-margin-left" />
      <Label className="ln-u-margin-left">
        {selectedGroups.length} groups selected.
      </Label>
    </div>
  );

  const buildCreateGroupInput = () => (
    <div style={{ float: "right", width: "30%" }} key={"createGroupInput"}>
      <GridItem style={{ display: "flex", height: "3rem" }}>
        <TextInput
          placeholder="Group name..."
          onChange={(e) => setCreateGroupInput(e.target.value)}
          value={createGroupInput}
        />
        <FilledButton
          style={{ width: "50%" }}
          className="ln-u-margin-left ln-u-margin-right*4"
          onClick={() => {
            productClient.createGroup(createGroupInput);
            setCreateGroupInput("");
          }}
        >
          Create Group
        </FilledButton>
      </GridItem>
    </div>
  );

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper>
        <GridItem>
          <div style={{ float: "left" }}>
            <Basket />
            &nbsp;&nbsp;
            <Label htmlFor="">
              {productMode
                ? "Products"
                : groupMode
                ? "Groups"
                : groupDetailMode
                ? "Group detail"
                : ""}
            </Label>
          </div>

          <Transition.Group
            animation="fade right"
            duration={TRANSITION_DURATION}
          >
            {productMode && buildSelectedProductsWidget()}
          </Transition.Group>

          <Transition.Group
            animation="fade right"
            duration={TRANSITION_DURATION}
          >
            {groupMode && [
              buildSelectedProductsWidget(),
              buildSelectedGroupsWidget(),
              buildCreateGroupInput(),
            ]}
          </Transition.Group>

          <Transition.Group
            animation="fade right"
            duration={TRANSITION_DURATION}
          >
            {groupDetailMode && buildGroupNameStatusTag()}
          </Transition.Group>
        </GridItem>

        <hr
          style={{
            border: "1px 0 0 0 solid grey",
            margin: "1rem 0rem 0rem 1rem",
          }}
        />

        <GridItem>
          <Transition.Group animation="fade up" duration={TRANSITION_DURATION}>
            {productMode && buildCategorySelectors()}
          </Transition.Group>
        </GridItem>

        <GridItem>
          <Modal
            open={confirmOpen}
            fullScreen
            restrictClose
            alert
            headingId="dialog-modal"
            className="modal-overflow"
          >
            <ModalHeading element="h3">{confirmMessage}</ModalHeading>
            <ButtonGroupPrimary>
              <FilledButton onClick={handleConfirm}>Ok</FilledButton>
            </ButtonGroupPrimary>
          </Modal>

          <Transition.Group animation="fade up" duration={TRANSITION_DURATION}>
            {productMode && buildProductSearch()}
          </Transition.Group>

          <Transition.Group animation="fade up" duration={TRANSITION_DURATION}>
            {groupMode && buildGroupList()}
          </Transition.Group>

          <Transition.Group animation="fade up" duration={TRANSITION_DURATION}>
            {groupDetailMode && buildGroupDetails()}
          </Transition.Group>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default ProductsView;
