import classnames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { validate as isValidUUID } from "uuid";

import { Accordion, AccordionItem } from "@jsluna/accordion";
import { Alert, AlertIcon } from "@jsluna/alert";
import { FilledButton, OutlinedButton } from "@jsluna/button";
import { Form } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { Basket, ErrorCircle, InfoCircle } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import ConfirmationModal from "../../common/components/ConfirmationModal";
import {
  CurrencyField,
  DateTimeField,
  NumberField,
  SingleSelectField,
  TagsField,
  TextField,
  YesNoField,
} from "../../common/components/FormFields";
import { InlineGroup } from "../../common/components/InlineGroup";
import PageContainer from "../../common/components/PageContainer";
import { PageHeader } from "../../common/components/PageHeader";
import { ProductSelector } from "../../common/components/ProductSelector";
import { toApiFormat } from "../../common/dates";
import {
  isValidHttpUrl,
  maybeValidEmail,
} from "../../common/helpers/validation";
import { useForm } from "../../common/hooks/useForm";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import "./CampaignEditor.scss";
import { validCampaignTypes } from "./options";

const displayProps = (hide, ...otherClassNames) => ({
  className: classnames({ "ln-u-visually-hidden": hide }, ...otherClassNames),
});
const getApiValue = (fieldProps) => {
  if (fieldProps.disabled) {
    return null;
  }
  if (fieldProps.value === "") {
    return null;
  }
  return fieldProps.value;
};

const convertToApiCurrencyFormat = (value) => {
  if (!value) {
    return value;
  }
  return value.replace(/[^0-9]/g, "");
};

const convertFromApiCurrencyFormat = (value) => {
  if (!value) {
    return value;
  }
  value = value.toString();
  value = value.replace(/^0+/, ""); // in case we have leading zeros already
  if (!value) {
    return ""; // return empty string if the value was all zeros
  }

  // Ensure that we have at least characters before formatting
  value = value.padStart(3, "0");
  const poundsEnd = value.length - 2;
  return value.slice(0, poundsEnd) + "." + value.slice(poundsEnd);
};

const CampaignEditor = ({
  campaignId,
  currentUsername,
  existingTags,
  loading,
  onSave,
  initialValues,
  apiErrorText,
  onCommit,
  onPause,
  onResume,
  onStop,
  generatedBarcode,
}) => {
  const isCmpgnEditor = HasRequiredRoleAccess("campaigns");
  const isCreateMode = !campaignId;
  const [requestedAction, setRequestedAction] = useState(null);
  const onDismiss = () => setRequestedAction(null);
  const {
    useField,
    anyValidationErrors,
    onSubmit,
    anyChanges,
    shouldBlockSubmit,
  } = useForm({ immediateValidation: !isCreateMode });

  const barcode = initialValues.barcode ?? null;

  const campaignType = useField({
    initialValue: initialValues.campaignType ?? "",
    options: validCampaignTypes,
  });

  const noCampaignType = !campaignType.value;
  const isMoneyOffCampaign = campaignType.value === "MONEY_OFF";
  const isProductCampaign = campaignType.value === "PRODUCT";
  const isBasketCampaign = campaignType.value === "BASKET";
  const isPointsMultiplierCampaign = campaignType.value === "POINTS_MULTIPLIER";
  const isSkuLimitedSpendCampaign = campaignType.value === "SKU_LIMITED_SPEND";
  const isMassVolumeCampaign = campaignType.value === "MASS_VOLUME";
  const isNectarPriceCampaign = campaignType.value === "NECTAR_PRICE";
  const isTriggerCampaign = campaignType.value === "TRIGGER";

  const showCommit = initialValues.active === false;
  const showStop =
    initialValues.active === true && initialValues.campaignStatus !== "STOPPED";
  const showPause =
    initialValues.active === true && initialValues.campaignStatus === "ACTIVE";
  const showResume =
    initialValues.active === true &&
    initialValues.campaignStatus === "SUSPENDED";

  const navigate = useNavigate();

  const accountClientType = useField({
    initialValue: initialValues.accountClientType ?? "",
    disabled: noCampaignType,
    options: [
      {
        label: "Welcome",
        value: "WELCOME",
        disabled: isNectarPriceCampaign,
      },
      {
        label: "Standard",
        value: "STANDARD",
        disabled: isNectarPriceCampaign,
      },
      {
        label: "Manual Assignment",
        value: "MANUAL_ASSIGNMENT",
        disabled: isNectarPriceCampaign,
      },
      {
        label: "Trade Driving",
        value: "TRADE_DRIVING",
        disabled: isNectarPriceCampaign,
      },
      {
        label: "Nectar Price",
        value: "NECTAR_PRICE",
        disabled: !isNectarPriceCampaign,
      },
    ],
  });

  const mode = useField({
    initialValue: initialValues.mode ?? "",
    disabled: noCampaignType || isNectarPriceCampaign,
    options: [
      { label: "Open", value: "OPEN" },
      { label: "Targeted", value: "TARGETED" },
    ],
  });

  const redeemableTransactionType = useField({
    initialValue: initialValues.redeemableTransactionType ?? "",
    disabled: noCampaignType,
    options: [
      { label: "All", value: "ALL" },
      { label: "In-Store", value: "IN_STORE", disabled: isNectarPriceCampaign },
      {
        label: "Groceries Online",
        value: "GROCERIES_ONLINE",
        disabled: isNectarPriceCampaign,
      },
    ],
  });

  const disableGenerateBarcodeField = () => {
    let disableGenerateBarcode = true;
    if (isCreateMode) {
      disableGenerateBarcode = false;
    }
    if (initialValues.campaignType !== campaignType.value) {
      disableGenerateBarcode = false;
    }
    return disableGenerateBarcode;
  };

  const generateBarcode = useField({
    initialValue: !!initialValues.barcode || !!initialValues.tillExport,
    disabled:
      noCampaignType ||
      isNectarPriceCampaign ||
      isMassVolumeCampaign ||
      isTriggerCampaign ||
      isMoneyOffCampaign ||
      disableGenerateBarcodeField(),
  });

  const loyaltyCardRequiredToggle = useField({
    initialValue: !!initialValues.loyaltyCardRequired,
    disabled:
      noCampaignType ||
      isProductCampaign ||
      isBasketCampaign ||
      isPointsMultiplierCampaign ||
      isSkuLimitedSpendCampaign ||
      isMassVolumeCampaign ||
      isNectarPriceCampaign ||
      isTriggerCampaign,
  });

  const isOCCMoneyOff =
    isMoneyOffCampaign &&
    accountClientType.value === "MANUAL_ASSIGNMENT" &&
    mode.value === "TARGETED";

  const isNBMMoneyOff =
    isMoneyOffCampaign &&
    (accountClientType.value !== "MANUAL_ASSIGNMENT" ||
      mode.value !== "TARGETED");

  const tillExport =
    (isMoneyOffCampaign &&
      (redeemableTransactionType.value === "ALL" ||
        redeemableTransactionType.value === "IN_STORE")) ||
    (generateBarcode.value && !generateBarcode.disabled);

  const description = useField({
    initialValue:
      (isNectarPriceCampaign
        ? initialValues.campaignText1
        : initialValues.name) ?? "",
    disabled: noCampaignType,
    requiredIfActive: !isNectarPriceCampaign,
  });

  const name = useField({
    initialValue: (isNectarPriceCampaign ? initialValues.name : "") ?? "",
    disabled: noCampaignType || !isNectarPriceCampaign,
  });

  const offerCodeOptionalWithDefault = !noCampaignType && !tillExport;

  const offerCode = useField({
    initialValue: initialValues.offerCode ?? "",
    disabled: !isNBMMoneyOff && !isOCCMoneyOff && !offerCodeOptionalWithDefault,
    requiredIfActive: false,
  });

  //Moved up in this file for promo id check
  const useItemQuantity = useField({
    initialValue:
      !!(!initialValues.qualifyingSpend && initialValues.itemQuantity) ?? false,
    disabled: !isMoneyOffCampaign,
  });

  const promotionId = useField({
    initialValue: initialValues.promotionId ?? "",
    disabled: (!isNBMMoneyOff && !tillExport) || isBasketCampaign,
    requiredIfActive: isProductCampaign || isSkuLimitedSpendCampaign,
    validations: [
      [(v) => !v || /^\d+$/.test(v), "Must be a valid promotion ID"],
    ], // Do we need to check if it is actually a real id
  });

  const tags = useField({
    initialValue: initialValues.tags ?? [],
    disabled: noCampaignType,
    requiredIfActive: false,
  });

  const defaultNectarPrice = useField({
    initialValue:
      convertFromApiCurrencyFormat(initialValues.defaultNectarPrice) ?? "",
    disabled: !isNectarPriceCampaign,
    requiredIfActive: false,
    validations: [
      [
        (v) => !v || /^\d+(.\d\d)?$/.test(v),
        "Must be a whole number of pounds, or pounds and two pence digits",
      ],
    ],
  });

  const defaultFromPrice = useField({
    initialValue:
      convertFromApiCurrencyFormat(initialValues.defaultFromPrice) ?? "",
    disabled: !isNectarPriceCampaign,
    requiredIfActive: false,
    validations: [
      [
        (v) => !v || /^\d+(.\d\d)?$/.test(v),
        "Must be a whole number of pounds, or pounds and two pence digits",
      ],
    ],
  });

  const minimumPoints = useField({
    initialValue: initialValues.minimumPoints?.toString() ?? "",
    disabled:
      (!isProductCampaign || tillExport) &&
      (!isBasketCampaign || tillExport) &&
      (!isSkuLimitedSpendCampaign || tillExport) &&
      !isMassVolumeCampaign,
    validations: [
      [
        (v) =>
          (!isProductCampaign &&
            !isBasketCampaign &&
            !isSkuLimitedSpendCampaign) ||
          Number(v) >= 1,
        "Must be greater than 0",
      ],
    ],
  });

  const maximumPoints = useField({
    initialValue: initialValues.maximumPoints?.toString() ?? "",
    disabled:
      !isProductCampaign &&
      !isBasketCampaign &&
      !isSkuLimitedSpendCampaign &&
      !isMassVolumeCampaign,
    validations: [
      [
        (v) =>
          (!isProductCampaign &&
            !isBasketCampaign &&
            !isSkuLimitedSpendCampaign) ||
          Number(v) >= 1,
        "Must be greater than 0",
      ],
      [
        (v) =>
          (!isProductCampaign &&
            !isBasketCampaign &&
            !isSkuLimitedSpendCampaign) ||
          (!isCreateMode && tillExport) ||
          Number(v) >= (Number(minimumPoints.value) || 1),
        "Must be greater than or equal to Minimum Points",
      ],
    ],
  });

  const pointsMultiplierValue = useField({
    initialValue: initialValues.pointsMultiplierValue?.toString() ?? "",
    disabled: !isPointsMultiplierCampaign,
    options: ["2", "3", "4", "5", "6", "7", "8", "9"].map((val) => ({
      label: "×" + val,
      value: val,
    })),
  });

  const productRange = useField({
    initialValue: initialValues.productRange ?? "",
    disabled: !isPointsMultiplierCampaign,
    options: [
      { label: "Goods only", value: "GOODS_ONLY" },
      { label: "Fuel only", value: "FUEL_ONLY" },
      { label: "Goods & Fuel", value: "GOODS_OR_FUEL" },
    ],
  });

  const discountAmount = useField({
    initialValue:
      convertFromApiCurrencyFormat(initialValues.discountAmount) ?? "",
    disabled: !isMoneyOffCampaign,
    validations: [
      [
        (v) => /^\d+(.\d\d)?$/.test(v),
        "Must be a whole number of pounds, or pounds and two pence digits",
      ],
      [
        (v) => /^(?!0(\.0{1,2})?$)(\d+(\.\d{1,2})?)$/.test(v),
        "Must be greated than 0.00",
      ],
    ],
  });

  //useItemQuantity moved up for promotion id check

  const qualifyingSpend = useField({
    initialValue:
      convertFromApiCurrencyFormat(initialValues.qualifyingSpend) ?? "",
    disabled:
      !isTriggerCampaign &&
      !isPointsMultiplierCampaign &&
      !isBasketCampaign &&
      (!isSkuLimitedSpendCampaign || !tillExport) &&
      (!isMoneyOffCampaign || useItemQuantity.value),
    validations: [
      [
        (v) => /^\d+(.\d\d)?$/.test(v),
        "Must be a whole number of pounds, or pounds and two pence digits",
      ],
      [
        (v) => /^(?!0(\.0{1,2})?$)(\d+(\.\d{1,2})?)$/.test(v),
        "Must be greated than 0.00",
      ],
    ],
  });

  const itemQuantity = useField({
    initialValue: initialValues.itemQuantity?.toString() ?? "",
    disabled:
      (!isMoneyOffCampaign || !useItemQuantity.value) &&
      (!isProductCampaign || !tillExport),
    validations: [
      [(v) => !isProductCampaign || v >= 1, "Must be greater than 0"],
    ],
  });

  const unit = useField({
    initialValue: initialValues.unit ?? "",
    disabled: !isSkuLimitedSpendCampaign && !isMassVolumeCampaign,
    options:
      isSkuLimitedSpendCampaign && tillExport
        ? [
            {
              label: "POUNDS",
              value: "PENCE",
            },
          ]
        : [
            { label: "PENCE", value: "PENCE" },
            {
              label: "POUNDS",
              value: "POUNDS",
              disabled: true && isSkuLimitedSpendCampaign,
            }, // Never accepted right now
            {
              label: "MILLILITRE",
              value: "MILLILITRE",
              disabled: isSkuLimitedSpendCampaign,
            },
            {
              label: "GRAMS",
              value: "GRAMS",
              disabled: isSkuLimitedSpendCampaign,
            },
          ],
  });

  const step = useField({
    initialValue: initialValues.step ?? "",
    disabled:
      (!isSkuLimitedSpendCampaign || tillExport) && !isMassVolumeCampaign,
    validations: [
      [(v) => !isSkuLimitedSpendCampaign || v > 0, "Must be greater than 0"],
    ],
  });

  const minimumUnits = useField({
    initialValue: initialValues.minimumUnits?.toString() ?? "",
    disabled:
      (!isSkuLimitedSpendCampaign || tillExport) && !isMassVolumeCampaign,
    validations: [
      [(v) => !isSkuLimitedSpendCampaign || v > 0, "Must be greater than 0"],
    ],
  });

  const maximumUnits = useField({
    initialValue: initialValues.maximumUnits?.toString() ?? "",
    disabled:
      (!isSkuLimitedSpendCampaign || tillExport) && !isMassVolumeCampaign,
    validations: [
      [(v) => !isSkuLimitedSpendCampaign || v > 0, "Must be greater than 0"],
    ],
  });

  const triggerCampaignId = useField({
    initialValue: initialValues.triggerCampaignId ?? "",
    disabled: !isTriggerCampaign,
    validations: [[isValidUUID, "Must be a valid campaign ID"]], // Do we need to check if it is actually a real id
  });

  const startDate = useField({
    initialValue: initialValues.startDate ?? "",
    disabled: noCampaignType,
  });

  const endDate = useField({
    initialValue: initialValues.endDate ?? "",
    disabled: noCampaignType,
    validations: [
      [
        (v) => v >= toApiFormat(new Date()),
        "Expiry date must not be in the past",
      ],
      [
        (v) => !startDate.value || v > startDate.value,
        "Must be after Campaign Start",
      ],
    ],
  });

  const offerBehaviourSameAsCampaign = useField({
    // The API sets default values that make it seem like this section was completed on create
    initialValue:
      isCreateMode ||
      (initialValues.startDateBehaviour === "START_WHEN_ISSUED" &&
        initialValues.endDateBehaviour === "END_DATE_OF_CAMPAIGN"),
    disabled: noCampaignType || isNectarPriceCampaign,
  });

  const startDateBehaviour = useField({
    initialValue: initialValues.startDateBehaviour ?? "",
    disabled: isNectarPriceCampaign || offerBehaviourSameAsCampaign.value,
    options: [
      { label: "Start when issued", value: "START_WHEN_ISSUED" },
      { label: "Start date of campaign", value: "START_DATE_OF_CAMPAIGN" },
      { label: "Start on specified date", value: "START_ON_SPECIFIED_DATE" },
    ],
  });

  const endDateBehaviour = useField({
    initialValue: initialValues.endDateBehaviour ?? "",
    disabled: isNectarPriceCampaign || offerBehaviourSameAsCampaign.value,
    options: [
      { label: "End date of campaign", value: "END_DATE_OF_CAMPAIGN" },
      { label: "End on specified date", value: "END_ON_SPECIFIED_DATE" },
      { label: "Expire after n days", value: "END_AFTER_N_DAYS" },
    ],
  });

  const offerStartDate = useField({
    initialValue: initialValues.offerStartDate ?? "",
    disabled:
      isNectarPriceCampaign ||
      offerBehaviourSameAsCampaign.value ||
      startDateBehaviour.value !== "START_ON_SPECIFIED_DATE",
  });

  const offerEndDate = useField({
    initialValue: initialValues.offerEndDate ?? "",
    disabled:
      isNectarPriceCampaign ||
      offerBehaviourSameAsCampaign.value ||
      endDateBehaviour.value !== "END_ON_SPECIFIED_DATE",
  });

  const defaultExpiry = useField({
    initialValue: initialValues.defaultExpiry?.toString() ?? "",
    disabled:
      isNectarPriceCampaign ||
      offerBehaviourSameAsCampaign.value ||
      endDateBehaviour.value !== "END_AFTER_N_DAYS",
  });

  const disableLimitMaxRedemptions = () => {
    let disablelimitMaxRedemptionField = true;
    if (campaignType.value === "POINTS_MULTIPLIER") {
      disablelimitMaxRedemptionField = false;
    }
    return disablelimitMaxRedemptionField;
  };

  const limitMaxRedemptions = useField({
    // The API requires values that make it seem like this section was completed on create
    initialValue: initialValues.limitMaxRedemptions ?? true,
    disabled: noCampaignType || disableLimitMaxRedemptions() || tillExport,
    // disabled:false,
  });
  const artworkUrl = useField({
    initialValue: initialValues.artworkUrl ?? "",
    disabled: noCampaignType,
    requiredIfActive: false,
    validations: [[(v) => !v || isValidHttpUrl(v), "Must be a valid URL"]],
  });

  const selectedProducts = useField({
    initialValue: [
      ...(initialValues.skusDescriptions ?? []).map(({ id, description }) => ({
        id,
        description,
        type: "sku",
      })),
    ],
    disabled:
      noCampaignType ||
      isBasketCampaign ||
      isPointsMultiplierCampaign ||
      isTriggerCampaign ||
      (isProductCampaign && tillExport) ||
      (isSkuLimitedSpendCampaign && tillExport) ||
      isMoneyOffCampaign,
    requiredIfActive: false, // For custom error message
    validations: [[(v) => v.length > 0, "Please select at least one SKU"]],
  });

  // When there are no visible fields, just hide the whole section
  const hideFundingFields = !tillExport || isNBMMoneyOff;

  const accountCode = useField({
    initialValue: initialValues.accountCode?.toString() ?? "",
    disabled: !tillExport || isNBMMoneyOff,
    validations: [[(v) => /^\d{5}$/.test(v), "Must be 5 numbers"]],
  });

  const costCentre = useField({
    initialValue: initialValues.costCentre?.toString() ?? "",
    disabled: !tillExport || isNBMMoneyOff,
    validations: [
      [
        (v) => /^[A-Za-z]\d{4}$/.test(v),
        "Must be a letter followed by 4 numbers",
      ],
    ],
  });

  const estimatedDistributionQuantity = useField({
    initialValue: initialValues.estimatedDistributionQuantity?.toString() ?? "",
    disabled: !tillExport || isNBMMoneyOff,
  });

  const estimatedRedemptionRate = useField({
    initialValue: initialValues.estimatedRedemptionRate?.toString() ?? "",
    disabled: !tillExport || isNBMMoneyOff,
  });

  const isSupplierFunded = useField({
    initialValue:
      !!initialValues.supplierName ||
      !!initialValues.phoebusCode ||
      !!initialValues.i2cContactEmail,
    disabled: !tillExport || isNBMMoneyOff,
  });

  const hideSupplierDetails = !tillExport || !isSupplierFunded.value;

  const supplierName = useField({
    initialValue: initialValues.supplierName ?? "",
    disabled: hideSupplierDetails,
  });

  const phoebusCode = useField({
    initialValue: initialValues.phoebusCode ?? "",
    disabled: hideSupplierDetails,
    validations: [[(v) => v.length === 5, "Must be 5 characters"]],
  });

  const i2cContactEmail = useField({
    initialValue: initialValues.i2cContactEmail ?? "",
    disabled: hideSupplierDetails,
    validations: [[maybeValidEmail, "Must be a valid email address"]],
  });

  const willGenerateBarcode =
    tillExport &&
    (campaignType.changed ||
      maximumPoints.changed ||
      discountAmount.changed ||
      pointsMultiplierValue.changed ||
      productRange.changed ||
      accountClientType.changed ||
      mode.changed ||
      !initialValues.barcode);

  const validationErrors = anyValidationErrors();
  const changesMade = anyChanges();

  const onSaveSubmit = onSubmit((e) => {
    e.preventDefault();

    const requestBody = {
      id: campaignId,
      barcode: barcode,
      campaignStatus: initialValues.campaignStatus ?? null, // "ACTIVE". Never required by API, defaults to ACTIVE
      displayType: initialValues.displayType ?? null, // Can maybe remove this. Never required by API, defaults to NORMAL

      createdBy: campaignId ? initialValues.createdBy : currentUsername, // Never required by API

      // Need to validate these
      allowOverride: initialValues.allowOverride ?? true, // Required. seems to default to true on the existing form

      campaignType: getApiValue(campaignType),
      accountClientType: getApiValue(accountClientType),
      mode: getApiValue(mode), // Never required by API, defaults to TARGETED
      redeemableTransactionType: getApiValue(redeemableTransactionType), // Never required by API, defaults to ALL

      name: getApiValue(description), // Never required by API, API defaults to first selected sku, when SKUs are set
      offerCode: getApiValue(offerCode), // Never required by API
      promotionId: getApiValue(promotionId), // Never required by API
      tags: tags.disabled || tags.value.length === 0 ? null : tags.value, // Never required by API, defaults to empty list

      defaultNectarPrice: convertToApiCurrencyFormat(
        getApiValue(defaultNectarPrice)
      ), // Never required by API
      defaultFromPrice: convertToApiCurrencyFormat(
        getApiValue(defaultFromPrice)
      ), // Never required by API
      minimumPoints: getApiValue(minimumPoints), // Not required for mass volume
      maximumPoints: getApiValue(maximumPoints), // Not required for mass volume
      pointsMultiplierValue: getApiValue(pointsMultiplierValue),
      productRange: getApiValue(productRange), // Never required by API
      discountAmount: convertToApiCurrencyFormat(getApiValue(discountAmount)),
      itemQuantity: getApiValue(itemQuantity), // Never required by API
      qualifyingSpend: convertToApiCurrencyFormat(getApiValue(qualifyingSpend)), // Not required for money off, if skus are set
      unit: getApiValue(unit),
      step: getApiValue(step),
      minimumUnits: getApiValue(minimumUnits),
      maximumUnits: getApiValue(maximumUnits),
      triggerCampaignId: getApiValue(triggerCampaignId),
      startDate: getApiValue(startDate),
      endDate: getApiValue(endDate),
      startDateBehaviour: getApiValue(startDateBehaviour), // Never required by API, defaults to START_WHEN_ISSUED
      endDateBehaviour: getApiValue(endDateBehaviour), // Never required by API, defaults to END_AFTER_N_DAYS
      offerStartDate: getApiValue(offerStartDate),
      offerEndDate: getApiValue(offerEndDate),
      defaultExpiry: getApiValue(defaultExpiry), // Never required by API, defaults to 8
      limitMaxRedemptions: limitMaxRedemptions.value,
      artworkUrl: getApiValue(artworkUrl), // Never required by API
      skus:
        selectedProducts.disabled || selectedProducts.value.length === 0
          ? null
          : selectedProducts.value
              ?.filter((n) => n.type === "sku")
              .map((rows) => rows.id), // Not required for money off, if qualifying spend is set

      accountCode: getApiValue(accountCode), // Never required by API
      costCentre: getApiValue(costCentre), // Never required by API
      estimatedDistributionQuantity: getApiValue(estimatedDistributionQuantity), // Never required by API
      estimatedRedemptionRate: getApiValue(estimatedRedemptionRate), // Never required by API
      supplierName: getApiValue(supplierName),
      phoebusCode: getApiValue(phoebusCode),
      i2cContactEmail: getApiValue(i2cContactEmail),
      tillExport: tillExport,
    };

    // For nectar price campaigns, where we actually have a name field, we shove description in the campaign text field
    if (isNectarPriceCampaign) {
      requestBody.campaignText1 = requestBody.name;
      requestBody.name = getApiValue(name);
      requestBody.limitMaxRedemptions = false;
    }

    // For some campaigns we only show max to the user, so min will be null. The API wants the value though.
    if (
      requestBody.minimumPoints === null &&
      requestBody.maximumPoints !== null
    ) {
      requestBody.minimumPoints = requestBody.maximumPoints;
    }

    // Hide minimumPoints and maximumPoints in OCC sku limited campaign but api wants the value.
    if (tillExport && isSkuLimitedSpendCampaign) {
      requestBody.minimumUnits = requestBody.qualifyingSpend;
      requestBody.maximumUnits = requestBody.qualifyingSpend;
    }

    if (offerBehaviourSameAsCampaign.value) {
      requestBody.startDateBehaviour = "START_WHEN_ISSUED";
      requestBody.endDateBehaviour = "END_DATE_OF_CAMPAIGN";
    }

    if (isMoneyOffCampaign) {
      requestBody.loyaltyCardRequired = loyaltyCardRequiredToggle.props.value;
    }

    const url = window.location.href;
    // Clear nulls
    Object.keys(requestBody).forEach((k) => {
      if (
        (requestBody[k] === null || requestBody[k] === undefined) &&
        k !== "offerCode"
      ) {
        if (url.includes("edit")) {
          delete requestBody[k];
        } else {
          if (k !== "offerCode") {
            delete requestBody[k];
          }
        }
      }
    });

    return onSave(requestBody, willGenerateBarcode);
  });

  const headerText = isCreateMode
    ? "Create new campaign"
    : `Campaign ${campaignId}`;

  return (
    <PageContainer className="campaign-editor">
      <Form onSubmit={onSaveSubmit}>
        <GridItem size="1/1" className="campaign-editor-header">
          <GridWrapper>
            <GridItem size={{ md: "2/3" }}>
              <PageHeader>
                <Basket />
                {headerText}
              </PageHeader>
            </GridItem>
            <GridItem size={{ md: "1/3" }}>
              <InlineGroup alignEnd>
                <OutlinedButton onClick={() => navigate("/campaigns")}>
                  Close
                </OutlinedButton>
                <FilledButton
                  onClick={onSaveSubmit}
                  disabled={
                    !isCmpgnEditor || (validationErrors && shouldBlockSubmit)
                  }
                >
                  Save
                </FilledButton>
                {showCommit && (
                  <FilledButton
                    onClick={onSubmit(() => setRequestedAction("commit"))}
                    disabled={validationErrors || changesMade}
                  >
                    Commit
                  </FilledButton>
                )}
                {showPause && (
                  <FilledButton
                    onClick={onSubmit(() => setRequestedAction("pause"))}
                    disabled={!isCmpgnEditor || validationErrors || changesMade}
                  >
                    Pause
                  </FilledButton>
                )}
                {showResume && (
                  <FilledButton
                    onClick={onSubmit(() => setRequestedAction("resume"))}
                    disabled={!isCmpgnEditor || validationErrors || changesMade}
                  >
                    Resume
                  </FilledButton>
                )}
                {showStop && (
                  <FilledButton
                    onClick={onSubmit(() => setRequestedAction("stop"))}
                    disabled={!isCmpgnEditor || validationErrors || changesMade}
                  >
                    Stop
                  </FilledButton>
                )}
              </InlineGroup>
            </GridItem>
            {generatedBarcode !== "" && (
              <GridItem size="1/1" className="campaign-editor-barcode">
                <h4>Barcode: {generatedBarcode}</h4>
              </GridItem>
            )}
            {!validationErrors &&
              willGenerateBarcode &&
              generatedBarcode === "" && (
                <GridItem size="1/1" className="ln-u-margin-top">
                  <Alert variant="info">
                    <AlertIcon>
                      <InfoCircle aria-label="Info" role="img" />
                    </AlertIcon>
                    A new barcode will be generated on save
                  </Alert>
                </GridItem>
              )}
            {validationErrors && shouldBlockSubmit && (
              <GridItem size="1/1" className="ln-u-margin-top">
                <Alert variant="error">
                  <AlertIcon>
                    <ErrorCircle aria-label="Error" role="img" />
                  </AlertIcon>
                  Please address validation errors before continuing
                </Alert>
              </GridItem>
            )}
            {apiErrorText && (
              <GridItem size="1/1" className="ln-u-margin-top">
                <Alert variant="error">
                  <AlertIcon>
                    <ErrorCircle aria-label="Error" role="img" />
                  </AlertIcon>
                  {apiErrorText}
                </Alert>
              </GridItem>
            )}
          </GridWrapper>
        </GridItem>

        <GridItem size="1/1">
          <GridWrapper>
            <GridItem size={{ md: "5/6" }}>
              <GridWrapper>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(campaignType.disabled)}
                >
                  <SingleSelectField
                    label="Campaign Type"
                    name="campaignType"
                    {...campaignType.props}
                    {...(!isCreateMode ? { disabled: true } : {})}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(accountClientType.disabled)}
                >
                  <SingleSelectField
                    label="Type"
                    name="accountClientType"
                    {...accountClientType.props}
                  />
                </GridItem>
                <GridItem size={{ md: "1/4" }} {...displayProps(mode.disabled)}>
                  <SingleSelectField label="Mode" name="mode" {...mode.props} />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(redeemableTransactionType.disabled)}
                >
                  <SingleSelectField
                    label="Allowed Redemption Channels"
                    name="redeemableTransactionType"
                    {...redeemableTransactionType.props}
                  />
                </GridItem>
              </GridWrapper>
            </GridItem>
            <GridItem
              size={{ md: "1/6" }}
              {...displayProps(generateBarcode.disabled)}
            >
              <YesNoField
                label="Generate Barcode?"
                name="generateBarcode"
                {...generateBarcode.props}
              />
            </GridItem>
            <GridItem
              size={{ md: "1/6" }}
              {...displayProps(loyaltyCardRequiredToggle.disabled)}
            >
              <YesNoField
                label="Loyalty Card Required?"
                name="loyaltyCardRequired"
                {...loyaltyCardRequiredToggle.props}
              />
            </GridItem>
          </GridWrapper>
        </GridItem>

        <GridItem size="1/1">
          <Accordion standalone multipleOpen>
            <AccordionItem
              title="Campaign Details"
              {...displayProps(noCampaignType)}
              defaultOpen
            >
              <GridWrapper>
                <GridItem size="1/1">
                  <GridWrapper>
                    <GridItem
                      size={isMoneyOffCampaign ? "1/1" : "1/2"}
                      {...displayProps(description.disabled)}
                    >
                      <TextField
                        label="Description"
                        name="description"
                        {...description.props}
                      />
                    </GridItem>
                    <GridItem
                      size={{ md: "1/2" }}
                      {...displayProps(name.disabled)}
                    >
                      <TextField label="Name" name="name" {...name.props} />
                    </GridItem>
                    <GridItem
                      size={{ md: "1/2" }}
                      {...displayProps(offerCode.disabled)}
                    >
                      <TextField
                        label="Offer Code"
                        name="offerCode"
                        {...offerCode.props}
                      />
                    </GridItem>
                    <GridItem
                      size={{ md: "1/2" }}
                      {...displayProps(promotionId.disabled)}
                    >
                      <NumberField
                        label="Promotion ID"
                        name="promotionId"
                        {...promotionId.props}
                      />
                    </GridItem>
                  </GridWrapper>
                </GridItem>
                <GridItem size="1/1" {...displayProps(tags.disabled)}>
                  <TagsField
                    label="Tags"
                    name="tags"
                    existingTags={existingTags}
                    allowAddingTags
                    placeholder="Select or add tags for campaign"
                    {...tags.props}
                  />
                </GridItem>
              </GridWrapper>
            </AccordionItem>
            <AccordionItem
              title="Campaign Rules"
              {...displayProps(noCampaignType)}
              defaultOpen
            >
              <GridWrapper>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(defaultNectarPrice.disabled)}
                >
                  <CurrencyField
                    label="Default Nectar Price"
                    name="defaultNectarPrice"
                    {...defaultNectarPrice.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(defaultFromPrice.disabled)}
                >
                  <CurrencyField
                    label="Default From Price"
                    name="defaultFromPrice"
                    {...defaultFromPrice.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(minimumPoints.disabled)}
                >
                  <NumberField
                    label="Minimum Points"
                    name="minimumPoints"
                    {...minimumPoints.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(maximumPoints.disabled)}
                >
                  <NumberField
                    label="Maximum Points"
                    name="maximumPoints"
                    {...maximumPoints.props}
                    {...(!isCreateMode ? { disabled: true } : {})}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(pointsMultiplierValue.disabled)}
                >
                  <SingleSelectField
                    name="pointsMultiplierValue"
                    label="Multiplier Value"
                    {...pointsMultiplierValue.props}
                    {...(!isCreateMode && isPointsMultiplierCampaign
                      ? { disabled: true }
                      : {})}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(productRange.disabled)}
                >
                  <SingleSelectField
                    label="Product Range"
                    name="productRange"
                    {...productRange.props}
                    {...(!isCreateMode && isPointsMultiplierCampaign
                      ? { disabled: true }
                      : {})}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "3/8" }}
                  {...displayProps(discountAmount.disabled)}
                >
                  <CurrencyField
                    label="Money Off Discount (in pounds)"
                    name="discountAmount"
                    {...discountAmount.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(useItemQuantity.disabled)}
                >
                  <YesNoField
                    label="Use Item Quantity?"
                    name="useItemQuantity"
                    {...useItemQuantity.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "3/8" }}
                  {...displayProps(qualifyingSpend.disabled)}
                >
                  <CurrencyField
                    label="Qualifying Spend (in pounds)"
                    name="qualifyingSpend"
                    {...qualifyingSpend.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "3/8" }}
                  {...displayProps(itemQuantity.disabled)}
                >
                  <NumberField
                    label="Item Quantity"
                    name="itemQuantity"
                    {...itemQuantity.props}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper>
                <GridItem size={{ md: "1/4" }} {...displayProps(unit.disabled)}>
                  <SingleSelectField label="Unit" name="unit" {...unit.props} />
                </GridItem>
                <GridItem size={{ md: "1/4" }} {...displayProps(step.disabled)}>
                  <NumberField label="Unit Step" name="step" {...step.props} />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(minimumUnits.disabled)}
                >
                  <NumberField
                    label="Minimum Units"
                    name="minimumUnits"
                    {...minimumUnits.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(maximumUnits.disabled)}
                >
                  <NumberField
                    label="Maximum Units"
                    name="maximumUnits"
                    {...maximumUnits.props}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper>
                <GridItem
                  size="1/1"
                  {...displayProps(triggerCampaignId.disabled)}
                >
                  <TextField
                    label="Trigger Campaign Id"
                    name="triggerCampaignId"
                    {...triggerCampaignId.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "3/8" }}
                  {...displayProps(startDate.disabled)}
                >
                  <DateTimeField
                    label="Campaign Start"
                    name="startDate"
                    defaultTime="00:00:00"
                    {...startDate.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "3/8" }}
                  {...displayProps(endDate.disabled)}
                >
                  <DateTimeField
                    label="Campaign End"
                    name="endDate"
                    {...endDate.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(offerBehaviourSameAsCampaign.disabled)}
                >
                  <YesNoField
                    label="Offer Behaviour Same As Campaign?"
                    name="offerBehaviourSameAsCampaign"
                    {...offerBehaviourSameAsCampaign.props}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(startDateBehaviour.disabled)}
                >
                  <SingleSelectField
                    label="Offer Start Date Behaviour"
                    name="startDateBehaviour"
                    {...startDateBehaviour.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(endDateBehaviour.disabled)}
                >
                  <SingleSelectField
                    label="Offer End Date Behaviour"
                    name="endDateBehaviour"
                    {...endDateBehaviour.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(offerStartDate.disabled)}
                >
                  <DateTimeField
                    label="Offer Start Date"
                    name="offerStartDate"
                    defaultTime="00:00:00"
                    {...offerStartDate.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(offerEndDate.disabled)}
                >
                  <DateTimeField
                    label="Offer End Date"
                    name="offerEndDate"
                    {...offerEndDate.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/2" }}
                  {...displayProps(defaultExpiry.disabled)}
                >
                  <NumberField
                    label="Default Expiry"
                    name="defaultExpiry"
                    {...defaultExpiry.props}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(limitMaxRedemptions.disabled)}
                >
                  <YesNoField
                    label="Limit Redemptions?"
                    name="limitMaxRedemptions"
                    {...limitMaxRedemptions.props}
                  />
                </GridItem>
                <GridItem size="1/1" {...displayProps(artworkUrl.disabled)}>
                  <TextField
                    label="Artwork"
                    name="artworkUrl"
                    {...artworkUrl.props}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper>
                <GridItem
                  size="1/1"
                  {...displayProps(selectedProducts.disabled)}
                >
                  <ProductSelector
                    showSubcategorySearch={false}
                    {...selectedProducts.props}
                  />
                </GridItem>
              </GridWrapper>
            </AccordionItem>
            <AccordionItem
              title="Campaign Funding"
              {...displayProps(hideFundingFields)}
              defaultOpen
            >
              <GridWrapper>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(accountCode.disabled)}
                >
                  <NumberField
                    label="Account Code"
                    name="accountCode"
                    {...accountCode.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(costCentre.disabled)}
                >
                  <TextField
                    label="Cost Centre"
                    name="costCentre"
                    {...costCentre.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(estimatedDistributionQuantity.disabled)}
                >
                  <NumberField
                    label="Estimated Distribution Quantity"
                    name="estimatedDistributionQuantity"
                    {...estimatedDistributionQuantity.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(estimatedRedemptionRate.disabled)}
                >
                  <NumberField
                    label="Estimated Redemption Rate"
                    name="estimatedRedemptionRate"
                    {...estimatedRedemptionRate.props}
                  />
                </GridItem>
              </GridWrapper>
              <GridWrapper>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(isSupplierFunded.disabled)}
                >
                  <YesNoField
                    label="Is Supplier Funded?"
                    name="isSupplierFunded"
                    {...isSupplierFunded.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(supplierName.disabled)}
                >
                  <TextField
                    label="Supplier Name"
                    name="supplierName"
                    {...supplierName.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(phoebusCode.disabled)}
                >
                  <TextField
                    label="Phoebus Code"
                    name="phoebusCode"
                    {...phoebusCode.props}
                  />
                </GridItem>
                <GridItem
                  size={{ md: "1/4" }}
                  {...displayProps(i2cContactEmail.disabled)}
                >
                  <TextField
                    label="Supplier Contact Email"
                    name="i2cContactEmail"
                    {...i2cContactEmail.props}
                  />
                </GridItem>
              </GridWrapper>
            </AccordionItem>
          </Accordion>
        </GridItem>
      </Form>
      <ConfirmationModal
        showModel={requestedAction === "commit"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await onCommit();
        }}
        heading="Commit campaign"
        message="This action will commit the selected campaign to Live!"
      />
      <ConfirmationModal
        showModel={requestedAction === "pause"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await onPause();
        }}
        heading="Pause campaign"
        message="This action will pause the selected campaign's redemptions!"
      />
      <ConfirmationModal
        showModel={requestedAction === "resume"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await onResume();
        }}
        heading="Resume campaign"
        message="This action will resume the selected campaign's redemptions!"
      />
      <ConfirmationModal
        showModel={requestedAction === "stop"}
        onDismiss={onDismiss}
        onConfirm={async () => {
          onDismiss();
          await onStop();
        }}
        heading="Stop campaign"
        message="This action will stop the selected campaign's redemptions!"
      />
      {loading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </PageContainer>
  );
};

export default CampaignEditor;
