import { PropTypes } from "prop-types";
import React from "react";

import ProductRow from "./ProductRow";

const ProductTableView = ({ products, selectedProducts, onProductSelect }) => {
  const handleOnProductSelected = (product) => {
    onProductSelect(product);
  };

  const productSelected = (prod) => {
    return selectedProducts.some(
      (x) => x.ean === prod.ean && x.sku === prod.sku
    );
  };

  const tableBody = products.map((product, i) => (
    <ProductRow
      key={i}
      product={product}
      onProductSelected={handleOnProductSelected}
      isSelected={productSelected(product)}
    />
  ));

  return (
    <div className="ln-c-table-container ln-u-margin-top*4">
      <table className="ln-c-table">
        <thead className="ln-c-table__header">
          <tr className="ln-c-table__row ln-c-table__header-row">
            <th className="ln-c-table__header-cell customCol">Select</th>
            <th className="ln-c-table__header-cell customCol">
              Ean Description
            </th>
            <th className="ln-c-table__header-cell customCol">SKU</th>
            <th className="ln-c-table__header-cell customCol">
              Super Category
            </th>
            <th className="ln-c-table__header-cell customCol">Category</th>
            <th className="ln-c-table__header-cell customCol">Sub Category</th>
            <th className="ln-c-table__header-cell customCol">Segment</th>
          </tr>
        </thead>
        <tbody className="ln-c-table__body">{tableBody}</tbody>
      </table>
    </div>
  );
};

ProductTableView.propTypes = {
  products: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  onProductSelect: PropTypes.func.isRequired,
};

export default ProductTableView;
