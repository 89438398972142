import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Button, Table } from "semantic-ui-react";

import LedgerClient from "../../services/LedgerClient";
import { LoadingState } from "../../services/http";
import TransactionRow from "./TransactionRow";

const ledgerClient = new LedgerClient();

const TransactionsView = ({ cardNumber }) => {
  const [transactions, setTransactions] = useState([]);
  const [loadingState, setLoadingState] = useState(LoadingState.PENDING);
  const [offset, setOffset] = useState(0);
  const [hasAllTransactions, setHasAllTransactions] = useState(false);
  const limit = 20;

  const collectorID = cardNumber.substr(cardNumber.length - 11);

  const getTransactions = () => {
    ledgerClient
      .getTransactions(collectorID, offset, limit)
      .then(({ data }) => {
        setTransactions((prevTransactions) =>
          data.items ? [...prevTransactions, ...data.items] : prevTransactions
        );
        setOffset((prevOffset) => prevOffset + limit);
        setHasAllTransactions(data.offset + limit >= data.total);
        setLoadingState(LoadingState.SUCCESS);
      })
      .catch((e) => {
        console.error(e);
        setLoadingState(LoadingState.FAILED);
      });
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContent = () => {
    switch (loadingState) {
      case LoadingState.SUCCESS:
        return transactions.length > 0 ? (
          <div>
            <Table celled striped basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Description</Table.HeaderCell>
                  <Table.HeaderCell>Store</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {transactions.map((row, i) => (
                  <TransactionRow key={i} transaction={row} />
                ))}
              </Table.Body>
            </Table>
            {!hasAllTransactions && (
              <Button onClick={getTransactions} content="Load more" />
            )}
          </div>
        ) : (
          <span>No transactions.</span>
        );
      case LoadingState.PENDING:
        return "Loading transactions...";
      default:
        return "Failed to load transactions.";
    }
  };

  return <div>{renderContent()}</div>;
};

TransactionsView.propTypes = {
  cardNumber: PropTypes.string.isRequired,
};

export default TransactionsView;
