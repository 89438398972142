import React, { useState } from "react";
import { toast } from "react-toastify";
import { Header, Image, Table } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  TextButton,
} from "@jsluna/button";
import { Label } from "@jsluna/form";
import { Delete } from "@jsluna/icons";
import { Modal, ModalHeading } from "@jsluna/react";

import CustomerClient from "../../services/CustomerClient";

const customerClient = new CustomerClient();

const OffersView = ({ offers, customer, isOA, reloadCustomer }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({});

  const formattedDate = (dateString) => {
    let date = new Date(dateString);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  const openConfimationDialog = (offer) => {
    setToggleModal(!toggleModal);
    setSelectedOffer(offer || {});
  };

  const cancelOffer = () => {
    const offerId = selectedOffer && selectedOffer.id;
    const customerId = customer && customer.id;
    customerClient
      .cancelCustomerOffer(customerId, offerId)
      .then((res) => {
        if (res.status === 200) {
          reloadCustomer();
          setToggleModal(false);
          toast.success("Action completed successfully");
        }
      })
      .catch((err) => {
        toast.error(err.message || "An error occurred");
      });
  };

  return offers.length > 0 ? (
    <React.Fragment>
      <Table style={{ width: "100%" }} basic="very" celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Offer</Table.HeaderCell>
            <Table.HeaderCell>Points</Table.HeaderCell>
            <Table.HeaderCell>Valid From</Table.HeaderCell>
            <Table.HeaderCell>Expires</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            {!isOA && <Table.HeaderCell>Cancel</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {offers.map((offer, key) => (
            <Table.Row key={key}>
              <Table.Cell>
                <Header as="h4">
                  <Image src={offer.imgUrl} shape="rounded" size="mini" />
                  <Header.Content>
                    {offer.title}
                    <Header.Subheader>{offer.description}</Header.Subheader>
                  </Header.Content>
                </Header>
              </Table.Cell>
              <Table.Cell>{offer.points}</Table.Cell>
              <Table.Cell>{formattedDate(offer.validFrom)}</Table.Cell>
              <Table.Cell>{formattedDate(offer.expires)}</Table.Cell>
              <Table.Cell>{offer.state}</Table.Cell>
              {!isOA && (
                <Table.Cell
                  style={{ cursor: "pointer" }}
                  onClick={() => openConfimationDialog(offer)}
                >
                  <Delete />
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => openConfimationDialog()}
        open={toggleModal}
        headingId="dialog-modal"
        className="custom-modal"
      >
        <ModalHeading>Confirm Action!</ModalHeading>
        <Label>Are you sure?</Label>
        <ButtonGroupWrapper actionbar>
          <ButtonGroupPrimary>
            <TextButton onClick={() => openConfimationDialog()}>
              Cancel
            </TextButton>
            <FilledButton
              className="ln-u-margin-left"
              onClick={() => cancelOffer()}
            >
              Delete
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </React.Fragment>
  ) : (
    <span>No offers.</span>
  );
};

export default OffersView;
