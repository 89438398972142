import React, { useEffect, useState } from "react";
import { Form, Grid, Icon, Message } from "semantic-ui-react";

import {
  ButtonGroupPrimary,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";

import SkuClient from "../../services/SkuClient";
import { LoadingState } from "../../services/http";
import { InitialState } from "./Sku";
import { SkuEditorForm } from "./SkuEditorForm";
import SkuTargetInput from "./skuforminputs/SkuTargetInput";

const skuClient = new SkuClient();

const SkuEditor = ({ currentSkuId, close, isActive }) => {
  const [sku, setSku] = useState({ targetSkuIds: [] });
  const [skuUpdateError, setSkuUpdateError] = useState(undefined);
  const [skuUpdateSuccess, setSkuUpdateSuccess] = useState(undefined);
  const [loadingState, setLoadingState] = useState(undefined);

  useEffect(() => {
    const reloadSku = () => {
      if (isNewSku()) {
        setSku(InitialState());
      } else {
        skuClient
          .fetchSku(currentSkuId)
          .then(({ data }) => {
            let updatedSku = setDefaultsForNullValues(data);
            setSku(updatedSku);
          })
          .catch((err) => {
            console.error(err);
            clearMessages(() => {
              setSkuUpdateError("Failed to load sku");
            });
          });
      }
    };
    reloadSku();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSkuId]);

  const isNewSku = () => currentSkuId === null;

  const setDefaultsForNullValues = (sku) => {
    if (!sku.targetSkuIds) {
      sku.targetSkuIds = [];
    }
    return sku;
  };

  const clearMessages = (cb) => {
    setSkuUpdateError(undefined);
    setSkuUpdateSuccess(undefined);
    if (cb) cb();
  };

  const handleSave = () => {
    clearMessages();
    createOrUpdateSku();
  };

  const createOrUpdateSku = () => {
    setSku({ ...sku });
    skuClient
      .createOrUpdateSku(sku)
      .then(() => {
        close();
      })
      .catch((err) => {
        clearMessages(() => {
          setSkuUpdateError(err.response.data.description);
          setLoadingState(LoadingState.FAILED);
        });
      });
  };

  const handleChange = (e, { name, value }) => {
    clearMessages(() => {
      setSku({
        ...sku,
        [name]: value,
      });
    });
  };

  const deleteSku = () => {
    const id = sku.skuId;
    skuClient
      .deleteSku(id)
      .then(() => close())
      .catch((err) => {
        clearMessages(() => {
          setSkuUpdateError(err.response.data.description);
          setLoadingState(LoadingState.FAILED);
        });
      });
  };

  const addNewTargetSkuId = (targetSkuId) => {
    const updatedTargetSkuIds = [
      ...new Set([...sku.targetSkuIds, targetSkuId]),
    ];
    setSku({
      ...sku,
      targetSkuIds: updatedTargetSkuIds,
    });
  };

  const removeTargetSkuId = (targetSkuId) => {
    const updatedTargetSkuIds = sku.targetSkuIds.filter(
      (id) => id !== targetSkuId
    );
    setSku({
      ...sku,
      targetSkuIds: updatedTargetSkuIds,
    });
  };

  if (!sku && !skuUpdateError) {
    return null;
  }

  return (
    <div>
      <Form onSubmit={handleSave} widths="equal">
        <ButtonGroupWrapper>
          <ButtonGroupSecondary>
            <OutlinedButton type="button" size="small" onClick={close}>
              Close
            </OutlinedButton>
          </ButtonGroupSecondary>
          <ButtonGroupPrimary>
            <FilledButton
              primary
              size="small"
              type="submit"
              disabled={isActive}
              loading={loadingState === LoadingState.PENDING}
            >
              Save
            </FilledButton>
            &nbsp;&nbsp;&nbsp;
            {!isNewSku() && (
              <FilledButton
                type="button"
                size="small"
                color="red"
                onClick={() => {
                  if (
                    window.confirm("Are you sure you wish to delete this sku?")
                  ) {
                    deleteSku();
                  }
                }}
              >
                Delete
              </FilledButton>
            )}
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <SkuEditorForm
                sku={sku}
                handleChange={handleChange}
                isNewSku={isNewSku()}
              />
              <br />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      <SkuTargetInput
        targetSkuIds={sku.targetSkuIds || []}
        addNewTargetSkuId={addNewTargetSkuId}
        removeTargetSkuId={removeTargetSkuId}
      />

      {skuUpdateError && (
        <Message attached="bottom" error>
          <Icon name="warning circle" />
          {skuUpdateError}
        </Message>
      )}

      {skuUpdateSuccess && (
        <Message attached="bottom" success>
          <Icon name="check" />
          Updated the Sku
        </Message>
      )}
    </div>
  );
};

export default SkuEditor;
