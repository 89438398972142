import PropTypes from "prop-types";
import React from "react";
import { Table } from "semantic-ui-react";

const TransactionRow = ({ transaction }) => {
  const isCredit = () => {
    const creditTransactions = ["NORMAL", "BASERATE", "BONUS"];
    return creditTransactions.includes(transaction.transactionType);
  };

  const description = () => {
    switch (transaction.transactionType) {
      case "NORMAL":
        return "Normal";
      case "BASERATE":
        return "Baserate";
      case "BONUS":
        return transaction.descriptionText;
      default:
        return "Unknown";
    }
  };

  const location = () => (transaction.location ? transaction.location : "N/A");

  return (
    <Table.Row>
      <Table.Cell width="1">{transaction.date}</Table.Cell>
      <Table.Cell width="3">{transaction.descriptionText}</Table.Cell>
      <Table.Cell width="3">{location()}</Table.Cell>
      <Table.Cell width="1" positive={isCredit()} negative={!isCredit()}>
        <div style={{ fontSize: "large" }}>
          {transaction.totalPointsDisplayValue} points
        </div>
        <br />
        <div style={{ fontSize: "small" }}>{description()}</div>
      </Table.Cell>
    </Table.Row>
  );
};

TransactionRow.propTypes = {
  transaction: PropTypes.shape({
    date: PropTypes.string.isRequired,
    location: PropTypes.string,
    transactionType: PropTypes.oneOf(["NORMAL", "BASERATE", "BONUS"])
      .isRequired,
    totalPointsDisplayValue: PropTypes.string.isRequired,
    reason: PropTypes.string,
    additionalInformation: PropTypes.string,
    details: PropTypes.shape({
      campaignName: PropTypes.string.isRequired,
      pointsDisplayValue: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TransactionRow;
